<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import {
  OperatorProductConfigurationViewDataQuery,
  OperatorProductConfigurationViewDataQueryVariables,
  VersionStatus,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { itemSortByName } from '@/app'
import { useRoute, useRouter } from 'vue-router'
import SelectField from '@/components/input/SelectField.vue'
import OperatorBreadcrumb from '@/components/operator/OperatorBreadcrumb.vue'
import CreateProductionBatch from '@/components/productionBatch/CreateProductionBatch.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import useCurrentProductionBatch from '@/components/operator/currentProductionBatch'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const productId = computed(() => route.params.productId as string)

type LocalSite = OperatorProductConfigurationViewDataQuery['site']['sites'][0]
type LocalProdConfig =
  OperatorProductConfigurationViewDataQuery['product']['product']['productConfigurations'][0]
type LocalBop = LocalProdConfig['bops'][0]
type LocalModule = LocalBop['nodes'][0]['module']

const fetchQuery = useQuery<
  OperatorProductConfigurationViewDataQuery,
  OperatorProductConfigurationViewDataQueryVariables
>(
  gql`
    query OperatorProductConfigurationViewData($productId: ID!) {
      site {
        sites {
          id
          name
        }
      }
      product {
        product(id: $productId) {
          productConfigurations {
            id
            name
            abbreviation
            description
            imageUploadId
            imageUpload {
              id
              imageUrl(options: { width: 500, height: 300 })
            }
            bops {
              id
              site {
                id
                name
              }
              version
              versionStatus
              nodes {
                module {
                  id
                  name
                  description
                  abbreviation
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    productId: productId.value,
  }),
)
const productConfigs = computed<LocalProdConfig[]>(() =>
  itemSortByName(
    fetchQuery.result.value?.product.product.productConfigurations || [],
    (i) => i.name,
  ),
)
const sites = computed<LocalSite[]>(() => fetchQuery.result.value?.site.sites || [])

const bopOptions = (configId: string, siteId: string) =>
  productConfigs.value
    .filter((c) => c.id == configId)
    .flatMap((c) =>
      c.bops
        .filter((b) => b.site.id == siteId)
        .filter((b) => b.versionStatus == VersionStatus.Released),
    )
    .toSorted((a, b) => b.version - a.version)
    .map((b) => ({
      title: b.version,
      value: b,
    }))
const selectedBops = reactive<Record<string, LocalBop | undefined>>({})
const selectBop = (configId: string, siteId: string, bop: LocalBop) => {
  selectedBops[`${configId}-${siteId}`] = bop
}
const getSelectedBop = (configId: string, siteId: string) => selectedBops[`${configId}-${siteId}`]
watch([productConfigs, sites], () => {
  productConfigs.value.forEach((c) => {
    sites.value.forEach((s) => {
      selectBop(c.id, s.id, bopOptions(c.id, s.id)[0]?.value)
    })
  })
})

const moduleOptions = (bop?: LocalBop) =>
  bop
    ? itemSortByName(bop.nodes, (n) => n.module.name).map((n) => ({
        title: n.module.name,
        value: n.module,
      }))
    : []
const selectedModules = reactive<Record<string, LocalModule | undefined>>({})
const selectModule = (configId: string, siteId: string, module: LocalModule) => {
  const bop = getSelectedBop(configId, siteId) as LocalBop
  selectedModules[bop.id] = module
}
const getSelectedModule = (configId: string, siteId: string) => {
  const bop = getSelectedBop(configId, siteId)
  return bop ? selectedModules[bop.id] : undefined
}
watch(selectedBops, (v) => {
  Object.keys(v).forEach((k) => {
    const bop = v[k]
    if (!bop) {
      return
    }

    selectedModules[bop.id] = moduleOptions(bop)[0]?.value
  })
})

const { deselectCurrentProductionBatch, setCurrentProductionBatch } = useCurrentProductionBatch()
const createProductionBatchDialog = ref<ComponentExposed<typeof CreateProductionBatch>>()
const doCreateProductionBatch = async (configId: string, siteId: string) => {
  const bop = getSelectedBop(configId, siteId) as LocalBop
  const module = getSelectedModule(configId, siteId) as LocalModule

  const batchId = await createProductionBatchDialog.value?.open(bop.id, module.id)
  if (!batchId) {
    return
  }

  setCurrentProductionBatch(batchId)
  goToModule(configId, siteId)
}
const doViewModule = (configId: string, siteId: string) => {
  deselectCurrentProductionBatch()
  goToModule(configId, siteId)
}
const goToModule = (configId: string, siteId: string) => {
  const bop = getSelectedBop(configId, siteId) as LocalBop
  const module = getSelectedModule(configId, siteId) as LocalModule

  router.push({
    name: 'operatorModule',
    params: {
      productConfigId: configId,
      siteId: siteId,
      bopId: bop.id,
      moduleId: module.id,
    },
  })
}
</script>

<template>
  <operator-breadcrumb />
  <h1>
    {{ t('entity.productConfiguration.plural') }}
  </h1>

  <v-alert v-if="productConfigs.length == 0 && !fetchQuery.loading" type="info">
    {{ t('view.organization.operatorProductConfiguration.noData') }}
  </v-alert>
  <v-row>
    <v-col v-for="config in productConfigs" :key="config.id" xxl="2" xl="3" lg="3" sm="4" xs="6">
      <v-card>
        <v-img
          v-if="config.imageUpload?.imageUrl"
          :src="config.imageUpload.imageUrl"
          height="175px"
        />
        <v-card-title>{{ config.name }}</v-card-title>
        <v-card-text>
          <p>{{ config.description }}</p>

          <h3 class="mt-3 mb-1">{{ t('entity.bop.plural') }}</h3>
          <template v-for="(site, i) in sites" :key="site.id">
            <h4>{{ site.name }}</h4>

            <select-field
              :model-value="getSelectedBop(config.id, site.id)"
              :label="t('entity.bop.singular')"
              :items="bopOptions(config.id, site.id)"
              @update:model-value="(v: LocalBop) => selectBop(config.id, site.id, v)"
            />

            <select-field
              :label="t('entity.module.singular')"
              :model-value="getSelectedModule(config.id, site.id)"
              :items="moduleOptions(getSelectedBop(config.id, site.id))"
              @update:model-value="(v: LocalModule) => selectModule(config.id, site.id, v)"
            />

            <div class="text-right">
              <v-btn-group divided density="compact" variant="elevated" elevation="1">
                <v-btn
                  :disabled="!getSelectedModule(config.id, site.id)"
                  @click="doViewModule(config.id, site.id)"
                >
                  {{ t('view.organization.operatorProductConfiguration.buttonViewModule') }}
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="!getSelectedModule(config.id, site.id)"
                  @click="doCreateProductionBatch(config.id, site.id)"
                >
                  {{
                    t('view.organization.operatorProductConfiguration.buttonCreateProductionBatch')
                  }}
                </v-btn>
              </v-btn-group>
            </div>

            <v-divider v-if="i < sites.length - 1" class="mt-5 mb-3" />
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <create-production-batch ref="createProductionBatchDialog" />
</template>

<style scoped lang="scss"></style>
