<script setup lang="js">
import { watch } from 'vue'
import { currentUserAndPermissions } from '@/app'
import { useTheme } from 'vuetify'
import useTracker from '@/useTracker'

!(function (e, t, n) {
  function a() {
    var e = t.getElementsByTagName('script')[0],
      n = t.createElement('script')
    ;(n.type = 'text/javascript'),
      (n.async = !0),
      (n.src = 'https://beacon-v2.helpscout.net'),
      e.parentNode.insertBefore(n, e)
  }
  if (
    ((e.Beacon = n =
      function (t, n, a) {
        e.Beacon.readyQueue.push({ method: t, options: n, data: a })
      }),
    (n.readyQueue = []),
    'complete' === t.readyState)
  )
    return a()
  e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1)
})(window, document, window.Beacon || function () {})
window.Beacon('init', '5f666d96-e0eb-4237-be0b-936806082b71')

const theme = useTheme()

watch(currentUserAndPermissions, (v) => {
  if (!v) {
    return
  }

  Beacon('identify', {
    name: `${v.user.firstname} ${v.user.lastname}`,
    email: v.user.email,
    company: v.organization?.name,
    'organization-id': v.organization?.id,
    'user-id': v.user.id,
  })
})

const { trackerSessionUrl } = useTracker()
const openBeacon = () => {
  Beacon('session-data', {
    'Tracker Session URL': trackerSessionUrl.value,
    'Console Release': import.meta.env.VITE_GIT_SHA,
  })
  Beacon('config', {
    enableSounds: false,
    color: theme.themes.value.customLightTheme.colors.primary,
  })
  Beacon('toggle')
}
</script>

<template>
  <v-btn id="helpActivator" icon="help" variant="text" density="compact" @click="openBeacon" />
</template>

<style lang="scss">
#helpActivator {
  position: fixed;
  right: 0.1em;
  bottom: 0.1em;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
</style>
