<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  PermissionOrganization,
  UserOrganizationPermissionsDataQuery,
  UserOrganizationPermissionsDataQueryVariables,
  UserOrganizationPermissionsDeleteMutation,
  UserOrganizationPermissionsDeleteMutationVariables,
  UserOrganizationPermissionsSetMutation,
  UserOrganizationPermissionsSetMutationVariables,
} from '@/generated/graphql'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import OrganizationPermissionsPicker from '@/components/input/OrganizationPermissionsPicker.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import { currentOrganization } from '@/app'

const model = defineModel<string>({ required: false })

const { t } = useI18n()

const fetchQuery = useQuery<
  UserOrganizationPermissionsDataQuery,
  UserOrganizationPermissionsDataQueryVariables
>(
  gql`
    query UserOrganizationPermissionsData($id: ID!) {
      account {
        organizationUser(id: $id) {
          id
          firstname
          lastname
        }
        organizationPermissions(userId: $id)
      }
    }
  `,
  () => ({ id: model.value as string }),
  () => ({ enabled: !!model.value }),
)
const user = computed(() => fetchQuery.result.value?.account.organizationUser)
const permissions = computed(() => fetchQuery.result.value?.account.organizationPermissions || [])
const permissionsModel = ref<PermissionOrganization[]>([])
watch(permissions, (v) => (permissionsModel.value = v))

const notifySaved = ref(false)
const setPermissionsMutation = useMutation<
  UserOrganizationPermissionsSetMutation,
  UserOrganizationPermissionsSetMutationVariables
>(
  gql`
    mutation UserOrganizationPermissionsSet($command: SetOrganizationPermissions!) {
      account {
        setOrganizationPermissions(command: $command)
      }
    }
  `,
  {
    refetchQueries: ['getCurrentUserAndPermissions', 'getSwitchOrganizations'],
  },
)
const setOrgPermissions = () => {
  setPermissionsMutation
    .mutate({
      command: {
        orgId: currentOrganization.value?.id as string,
        permissions: permissionsModel.value,
        userId: model.value as string,
      },
    })
    .then(() => {
      notifySaved.value = true
    })
}
</script>

<template>
  <v-dialog
    max-width="650"
    :model-value="!!model"
    scrollable
    @update:model-value="() => (model = undefined)"
  >
    <v-card>
      <v-card-title> {{ user?.firstname }} {{ user?.lastname }} </v-card-title>
      <v-card-text>
        <organization-permissions-picker
          v-model="permissionsModel"
          density="compact"
          @update:model-value="() => setOrgPermissions()"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="model = undefined">
          {{ t('button.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss"></style>
