<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  CreateUser,
  OrgUserViewCreateMutation,
  OrgUserViewCreateMutationVariables,
  OrgUserViewDataQuery,
  OrgUserViewDataQueryVariables,
  PermissionOrganization,
  UserOrganizationPermissionsDeleteMutation,
  UserOrganizationPermissionsDeleteMutationVariables,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import TextField from '@/components/input/TextField.vue'
import UserEmail from '@/components/input/UserEmail.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableSearch from '@/components/dataTable/DataTableSearch.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import Checkbox from '@/components/input/Checkbox.vue'
import { mockCreateUser } from '@/generated/graphql-mocks'
import { createUserValidationRules } from '@/validation/user'
import OrganizationPermissionsPicker from '@/components/input/OrganizationPermissionsPicker.vue'
import UserOrganizationPermissions from '@/components/user/UserOrganizationPermissions.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import { currentOrganization } from '@/app'
import ButtonDelete from '@/components/button/ButtonDelete.vue'

const { t } = useI18n()

type LocalUser = OrgUserViewDataQuery['account']['organizationSearchUsers'][0]
const userContains = ref<string>()
const fetchQuery = useQuery<OrgUserViewDataQuery, OrgUserViewDataQueryVariables>(
  gql`
    query OrgUserViewData($contains: String) {
      account {
        organizationSearchUsers(criteria: { contains: $contains }) {
          id
          email
          firstname
          lastname
        }
      }
    }
  `,
  () => ({ contains: userContains.value }),
  { debounce: 500 },
)
const items = computed(() => fetchQuery.result.value?.account.organizationSearchUsers || [])

type CreateModel = Partial<CreateUser>
const createModelKeys = Object.keys(mockCreateUser())
const createModel = reactive<CreateModel>({})
const createPermissions = ref<PermissionOrganization[]>([])
const createValidation = useVuelidate<CreateModel>(createUserValidationRules, createModel)
const createMutation = useMutation<OrgUserViewCreateMutation, OrgUserViewCreateMutationVariables>(
  gql`
    mutation OrgUserViewCreate(
      $userCommand: CreateUser!
      $permissions: [PermissionOrganization!]!
    ) {
      account {
        inviteUserToOrganization(userCommand: $userCommand, permissions: $permissions)
      }
    }
  `,
)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  createModelKeys.forEach((k) => (createModel[k as keyof CreateModel] = undefined))
  createModel.needsPassword = true
  createPermissions.value = []

  await createDialog.value?.open(() =>
    createMutation
      .mutate({
        userCommand: createModel as CreateUser,
        permissions: createPermissions.value,
      })
      .then(() => {
        fetchQuery.refetch()
      }),
  )
}

const deleteOrgMutation = useMutation<
  UserOrganizationPermissionsDeleteMutation,
  UserOrganizationPermissionsDeleteMutationVariables
>(gql`
  mutation UserOrganizationPermissionsDelete($command: DeleteOrganizationPermissions!) {
    account {
      deleteOrganizationPermissions(command: $command)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const deleteOrg = async (user: LocalUser) => {
  await deleteDialog.value?.open(`${user.firstname} ${user.lastname}`, () =>
    deleteOrgMutation
      .mutate({
        command: {
          orgId: currentOrganization.value?.id as string,
          userId: user.id,
        },
      })
      .then(() => {
        fetchQuery.refetch()
      }),
  )
}

const headers = [
  {
    key: 'firstname',
    title: t('entity.user.field.firstname'),
  },
  {
    key: 'lastname',
    title: t('entity.user.field.lastname'),
  },
  {
    key: 'email',
    title: t('entity.user.field.email'),
  },
  {
    key: 'actions',
    width: '100',
    sortable: false,
    align: 'end',
  },
]
const sort = [
  { key: 'firstname', order: 'asc' },
  { key: 'lastname', order: 'asc' },
]

const selectedUserId = ref<string>()
</script>

<template>
  <h1>{{ t('entity.user.plural') }}</h1>

  <data-table
    :items="items"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    density="comfortable"
  >
    <template #header.actions>
      <data-table-search v-model="userContains" :loading="fetchQuery.loading" />
      <data-table-create-button @click="doCreate" />
    </template>
    <template #item.actions="{ item }: { item: LocalUser }">
      <button-delete @click="deleteOrg(item)" />
      <v-btn density="compact" variant="flat" icon="shield" @click="selectedUserId = item.id" />
    </template>
  </data-table>

  <upsert-dialog ref="createDialog" type="create" :validation="createValidation">
    <user-email v-model="createModel.email" :label="t('entity.user.field.email')" required />
    <text-field
      v-model="createModel.firstname"
      :label="t('entity.user.field.firstname')"
      required
      :validation="createValidation.firstname"
    />
    <text-field
      v-model="createModel.lastname"
      :label="t('entity.user.field.lastname')"
      required
      :validation="createValidation.lastname"
    />
    <checkbox v-model="createModel.needsPassword" :label="t('entity.user.field.needsPassword')" />

    <h3>{{ t('component.userPermissions.organizationPermissions') }}</h3>
    <organization-permissions-picker v-model="createPermissions" />
  </upsert-dialog>

  <user-organization-permissions v-model="selectedUserId" />
  <async-delete-dialog ref="deleteDialog" />
</template>

<style scoped lang="scss"></style>
